import './jquery-global';
import './styles.scss';


(function ($) {

    $(function () {

        'use strict';

        // DOM ready, take it away

        // MOBILE MENU submenu
        $('<div class="subarrow"><i class="fa-regular fa-chevron-down"></i></div>').insertAfter('.mobile-menu li.menu-item-has-children > a');
        $('.mobile-menu .subarrow').on('click', function () {
            if ($(this).parent().hasClass('active')) {
                $(this).parent().removeClass('active');
            } else {
                $(this).parent().addClass('active');
            }
        });


        // MOBILE TOGGLE
        // ===============================================

        $('.menu-toggle').on('click', function () {
            if ($('.mobile-menu').hasClass('active')) {
                $('.mobile-menu, .page-overlay').removeClass('active');
                $('body').removeClass('overflow-hidden');
            } else {
                $('.mobile-menu, .page-overlay').addClass('active');
                $('body').addClass('overflow-hidden');
            }
        });
        $('.page-overlay').on('click', function () {
            $('.mobile-menu, .page-overlay').removeClass('active');
            $('body').removeClass('overflow-hidden');
        });

        $('.mobile-menu ul li a, .header .logo').on('click', function () {
            $('body').removeClass('overflow-hidden');
        });

        // Close mobile menu on click
        $(document).click(function (event) {
            var $target = $(event.target);
            if (!$target.closest('.mobile-menu').length && !$target.closest('.menu-toggle').length && $('.mobile-menu').hasClass('active')) {
                $('.mobile-menu').removeClass('active');
                $('body').removeClass('overflow-hidden');
            }

        });


        // FORM TOGGLE
        // ===============================================
        $('#form .form-toggle').on('click', function () {
            if ($('#form').hasClass('active')) {
                $('#form').removeClass('active');
            } else {
                $('#form').addClass('active');
            }
        });


        // HEADER SCROLLED
        // ===============================================
        $(window).scroll(function () {
            var scroll = $(window).scrollTop();
            if (scroll >= 150) {
                $("header, body").addClass("scrolled");
            }

            if (scroll < 50) {
                $("header, body").removeClass("scrolled");
            }
        });


        AOS.init({
            once: true,
        });


        // Slick slider
        // ===============================================
        $('.team-slider').slick({
            dots: false,
            infinite: false,
            nextArrow: $('.ts-next'),
            prevArrow: $('.ts-prev'),
            speed: 300,
            mobileFirst: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,

                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });


    });

})(jQuery);